@font-face {
    font-family: 'Drive';
    src: url('Drive-MediumItalic.eot');
    src: url('Drive-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Drive-MediumItalic.woff2') format('woff2'),
        url('Drive-MediumItalic.woff') format('woff'),
        url('Drive-MediumItalic.ttf') format('truetype'),
        url('Drive-MediumItalic.svg#Drive-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Drive';
    src: url('Drive-Medium.eot');
    src: url('Drive-Medium.eot?#iefix') format('embedded-opentype'),
        url('Drive-Medium.woff2') format('woff2'),
        url('Drive-Medium.woff') format('woff'),
        url('Drive-Medium.ttf') format('truetype'),
        url('Drive-Medium.svg#Drive-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Drive';
    src: url('Drive-Bold.eot');
    src: url('Drive-Bold.eot?#iefix') format('embedded-opentype'),
        url('Drive-Bold.woff2') format('woff2'),
        url('Drive-Bold.woff') format('woff'),
        url('Drive-Bold.ttf') format('truetype'),
        url('Drive-Bold.svg#Drive-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Drive';
    src: url('Drive-BoldItalic.eot');
    src: url('Drive-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Drive-BoldItalic.woff2') format('woff2'),
        url('Drive-BoldItalic.woff') format('woff'),
        url('Drive-BoldItalic.ttf') format('truetype'),
        url('Drive-BoldItalic.svg#Drive-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Drive';
    src: url('Drive-Regular.eot');
    src: url('Drive-Regular.eot?#iefix') format('embedded-opentype'),
        url('Drive-Regular.woff2') format('woff2'),
        url('Drive-Regular.woff') format('woff'),
        url('Drive-Regular.ttf') format('truetype'),
        url('Drive-Regular.svg#Drive-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Drive';
    src: url('Drive-Light.eot');
    src: url('Drive-Light.eot?#iefix') format('embedded-opentype'),
        url('Drive-Light.woff2') format('woff2'),
        url('Drive-Light.woff') format('woff'),
        url('Drive-Light.ttf') format('truetype'),
        url('Drive-Light.svg#Drive-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Drive';
    src: url('Drive-Italic.eot');
    src: url('Drive-Italic.eot?#iefix') format('embedded-opentype'),
        url('Drive-Italic.woff2') format('woff2'),
        url('Drive-Italic.woff') format('woff'),
        url('Drive-Italic.ttf') format('truetype'),
        url('Drive-Italic.svg#Drive-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Drive';
    src: url('Drive-LightItalic.eot');
    src: url('Drive-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Drive-LightItalic.woff2') format('woff2'),
        url('Drive-LightItalic.woff') format('woff'),
        url('Drive-LightItalic.ttf') format('truetype'),
        url('Drive-LightItalic.svg#Drive-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

